<template>
  <div class="reset">
    <div class="_container reset__container">
      <h1 class="reset__title">{{ $t("login.forgot") }}</h1>
      <VeeForm @submit="resetPassword" class="reset__form">
        <div class="reset__item">
          <VeeField
            name="email"
            type="text"
            class="reset__input main-input"
            placeholder="Email*"
            v-model="email"
            rules="required|email"
          />
          <transition name="error">
            <VeeErrorMessage
              class="error-message"
              name="email"
            ></VeeErrorMessage>
          </transition>
        </div>
        <div class="reset__item" v-if="showCodeForm">
          <VeeField
            name="code"
            type="text"
            class="reset__input main-input"
            :placeholder="$t('placeholder.code')"
            v-model="code"
            rules="required|numeric"
          />
          <transition name="error">
            <VeeErrorMessage
              class="error-message"
              name="code"
            ></VeeErrorMessage>
          </transition>
        </div>
        <button type="submit" class="reset__btn main-btn">
          <span v-if="!showCodeForm">{{ $t("buttons.getCode") }}</span>
          <span v-else>{{ $t("buttons.sendCode") }}</span>
        </button>
      </VeeForm>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { useToast } from "vue-toastification";

export default {
  data() {
    return {
      email: "",
      code: "",
      showCodeForm: false,
    };
  },
  setup() {
    const toast = useToast();
    return { toast };
  },
  methods: {
    resetPassword() {
      if (!this.showCodeForm) {
        this.sendCode();
      } else this.checkCode();
    },
    sendCode() {
      axios
        .post("V1/password-reset", {
          email: this.email,
        })
        .then(() => {
          this.showCodeForm = true;
        })
        .catch(() => {
          this.toast.error(this.$t("errors.emailCode"));
        });
    },
    checkCode() {
      axios
        .post("V1/check-code", {
          email: this.email,
          code: this.code,
        })
        .then((response) => {
          localStorage.setItem("access_token", response.data.access_token);
          this.$router.push("/change-password");
        })
        .catch(() => {
          this.toast.error(this.$t("errors.wrongCode"));
        });
    },
  },
};
</script>

<style lang="scss">
.reset {
  padding: 100px 97px;
  @media (max-width: $mobile + px) {
    padding: 65px 0;
  }

  &__container {
    max-width: 460px;
  }

  &__form {
    &:not(:last-child) {
      margin: 0 0 30px 0;
    }
  }

  &__title {
    font-weight: 500;
    font-size: 24px;
    line-height: 29px;
    margin: 0 0 37px 0;
    text-align: center;
    @media (max-width: $mobile + px) {
      font-size: 20px;
    }
  }

  &__item {
    margin: 0 0 25px 0;
  }

  &__btn {
    width: 100%;
  }
}
</style>